<template>
    <el-dialog :show-close="true"	 title="地图" width="80%"     :visible.sync="visible" @close="onClose" :close-on-click-modal="false"  append-to-body   draggable>

		<div style="height: 650px">
			<div style="display: flex; flex-direction: row; width: 700px; column-gap: 12px; align-items: center; margin-bottom: 20px">
				<span style="width: 60px">地址：</span>
				<el-autocomplete
					v-model="form.address"
					:fetch-suggestions="querySearchAsync"
					placeholder="请输入地址搜索"
					@select="handleSelect"
					style="width: 500px"
				>
          <template #default="{ item }">
            <div>
              <div style="font-weight: 600">
                {{ item.title }}
              </div>
              <div style="font-size: 12px; line-height: 14px; padding-bottom: 10px">
<!--                <Location style="width: 14px; height: 14px; margin-right: 3px; position: relative; top: 2px" />-->
                {{ item.address }}
              </div>
            </div>
          </template>
				</el-autocomplete>
			</div>
			<div id="map-container" style="width: 100%; height: 600px"></div>
			<div id="toolControl">
				<div :class="['toolItem', form.currentMode === 'add' ? 'active' : '']" @click="mapAdd">
					<el-tooltip placement="bottom"  content="新增模式">
            <i class="el-icon-circle-plus-outline"> </i>
					</el-tooltip>
				</div>
				<div :class="['toolItem', form.currentMode === 'edit' ? 'active' : '']" @click="mapEdit">
					<el-tooltip placement="bottom"  content="编辑模式">
            <i class="el-icon-edit-outline"> </i>
					</el-tooltip>
				</div>
        <div :class="['toolItem', form.currentMode === 'addMarker' ? 'active' : '']" @click="mapAddMarker">
          <el-tooltip placement="bottom"  content="标点模式">
            <i class="el-icon-map-location"> </i>
          </el-tooltip>
        </div>
			</div>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="closeDialog">取消</el-button>
				<el-button type="primary" @click="onSubmit">确认</el-button>
			</span>
		</template>
	</el-dialog>
</template>
<script setup>
import { reactive, ref,nextTick } from 'vue'
const { TMap } = window;

const visible = ref(false);
const geocoder = ref(null);
const callbackFunc = ref(function () {

});

const form = reactive({
	address: '',
	currentMode: 'add',
	mode: 'add',
});

let map,
	searcher = null,
	editor = null,
    editorMarker = null,
	polygon = null,
markerLayer = null;

const onClose= (id) =>{
  closeDialog();
  visible.value = false;
}
const initMap = (geoInfo) => {
  console.log(geoInfo)
	//定义地图中心点坐标
	let center = new TMap.LatLng(31.127455, 104.397762);
  let mark=geoInfo;
	if (geoInfo.length > 0) {

    center = new TMap.LatLng(geoInfo[0].paths[0].lat,geoInfo[0].paths[0].lng);
    if(geoInfo[0].paths.length>2)
    {
     // center = TMap.geometry.computeCentroid(geoInfo[0].paths);
    }else {
      geoInfo=[];
    }
	}
	//定义map变量，调用 TMap.Map() 构造函数创建地图
  map = new TMap.Map(document.getElementById('map-container'), {
		center: center, //设置地图中心点坐标
		zoom: 17.2, //设置地图缩放级别
		pitch: 43.5, //设置俯仰角
		rotation: 45, //设置地图旋转角度
	});

	geocoder.value = new TMap.service.Geocoder();
	searcher = new TMap.service.Search({ pageSize: 10 });



	polygon = new TMap.MultiPolygon({
    map,
		geometries: geoInfo,
	});

	editor = new TMap.tools.GeometryEditor({
		map: map, // 编辑器绑定的地图对象
		overlayList: [
			{
				overlay: polygon,
				id: 'polygon',
			},
		],
		actionMode: TMap.tools.constants.EDITOR_ACTION.DRAW, // 编辑器的工作模式
		activeOverlayId: 'polygon', // 激活图层
		snappable: true, // 开启吸附
		selectable: true, // 开启点选功能
	});



   markerLayer = new TMap.MultiMarker({
    id: 'markerlayer',
    map: map,
    geometries: geoInfo,
  });

  //监听点击事件添加marker
  map.on("click", (evt) => {
    if(form.currentMode=="addMarker" )
    {
      markerLayer.setGeometries([])
      markerLayer.add({
        position: evt.latLng
      });
    }
  });

  if(mark.length > 0  && mark[0].paths.length==1)
  {

    markerLayer.setGeometries([])
    markerLayer.add({
      position:  mark[0].paths[0]
    });
  }



  editorMarker= new TMap.tools.GeometryEditor({
    map, // 编辑器绑定的地图对象
    overlayList: [{
      overlay: markerLayer, // 可编辑图层
      id: "marker",
      selectedStyleId: "highlight"  // 被选中的marker会变为高亮样式
    }],
    actionMode: TMap.tools.constants.EDITOR_ACTION.INTERACT, // 编辑器的工作模式
    activeOverlayId: "marker", // 激活图层
    selectable: true
  });


};

const querySearchAsync = (queryString, cb) => {
	if (queryString === '') {
		cb([]);
		return;
	}
	searcher
		.searchRegion({
			keyword: queryString,
			cityName: '德阳市',
			servicesk: '0ww2Sw8hcLA8V7oLryzNE6sG8T8CK6X',
		})
		.then((res) => {
			if (res.status === 0) {
				cb(res.data);
			}
		});
};

const mapAdd = () => {
	form.currentMode = 'add';
  editor ? editor.setActionMode(TMap.tools.constants.EDITOR_ACTION.DRAW) : '';
};

const mapEdit = () => {
	form.currentMode = 'edit';
  editor ? editor.setActionMode(TMap.tools.constants.EDITOR_ACTION.INTERACT) : '';
};

const mapAddMarker= () => {
  form.currentMode = 'addMarker';
  editor ? editor.setActionMode(TMap.tools.constants.EDITOR_ACTION.INTERACT) : '';
};

const mapEditMarker= () => {
  form.currentMode = 'editMarker';
  editor ? editorMarker.setActionMode(TMap.tools.constants.EDITOR_ACTION.INTERACT) : '';
};



const onSubmit = () => {
	mapAdd();
	if (callbackFunc.value) {
		let data = polygon.geometries.map((item) => {
			return item.paths.map((path) => ({
				lat: path.lat,
				lng: path.lng,
			}));
		});
    if(data.length==0)
    {
      data= markerLayer.geometries.map((item) => {
        return [item.position];

      });
    }
		callbackFunc.value(data);
	}

	closeDialog();
};

const handleSelect = (item) => {
	form.address = item.address;
  map.setCenter(item.location);
  map.setZoom(17.2);
};

const closeDialog = () => {
	visible.value = false;
	if (editor) {
		editor.destroy();
	}
	if (map) {
    map.destroy();
	}
	searcher = null;
};

// 打开弹窗
const openDialog = (address, geoInfo, callback) => {
	visible.value = true;
	callbackFunc.value = callback;
	form.address = address;
	// 重置表单数据
	nextTick(() => {
		const geos = geoInfo.map((arr) => {
			return {
				paths: arr.map((item) => new TMap.LatLng(item.lat, item.lng)),
			};
		});
		initMap(geos);
	});
};

// 暴露变量
defineExpose({
	openDialog,
});
</script>
<style scoped lang="scss">
#polygon {
	// background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/polygon.png');
}
.el-icon-circle-plus-outline {
  font-size: 30px;
}
 .el-icon-edit-outline{
  font-size: 30px;
}
 .el-icon-map-location{
   font-size: 30px;
 }
 .el-icon-edit{
   font-size: 30px;
 }
#toolControl {
	position: absolute;
	top: 150px;
	left: 30px;
	margin: auto;
	width: 252px;
	z-index: 1001;
}

.toolItem {
	width: 30px;
	height: 30px;
	float: left;
	margin: 1px;
	padding: 4px;
	border-radius: 3px;
	background-size: 30px 30px;
	background-position: 4px 4px;
	background-repeat: no-repeat;
	box-shadow: 0 1px 2px 0 #e4e7ef;
	background-color: #ffffff;
	border: 1px solid #ffffff;
	color: #7a9afc;
}

.toolItem:hover {
	border-color: #789cff;
}

.active {
	border-color: #789cff;
	background-color: #469cfc;
	color: #ffffff;
}
</style>
